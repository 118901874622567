import React, { useRef } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { handleBeforeunLoad } from "../../../utils/mixpanelEvents/mixpanelSignupFormEvent";
import { Image } from "@types";
import usePageSignupPageStore from "../../../sp-state/usePageSignupPageStore";

import styles from "./SignUpHeader.module.scss";
import { trackMxpEvent } from "@utils/mixpanelEvents/mixpanelEvents";

type SignUpHeaderType = {
  logoData: Image | null;
};

const SignUpHeader: React.FC<SignUpHeaderType> = ({ logoData }) => {
  const imgSrc = logoData && getImage(logoData.localFile)!;
  const { timeStart, timeEnd, resetPageState } = usePageSignupPageStore(
    state => state
  );

  const pageState = useRef({ timeStart, timeEnd });

  const handleLogoClick = () => {
    trackMxpEvent("user: Logo clicked");
    handleBeforeunLoad(pageState.current);
    resetPageState();
  };

  const logo = imgSrc ? (
    <GatsbyImage
      alt={logoData.altText || ""}
      image={imgSrc}
      className={styles.signUpHeaderLogo}
    />
  ) : (
    <svg className={styles.logo}>
      <use href="/icons/sp-logo.svg#sp-logo-forest"></use>
    </svg>
  );

  return (
    <header className={styles.landingPageSignUpHeader}>
      <nav className={`${styles.signUpHeaderNav} full-width`}>
        <Link className={styles.logoWrapper} to="/" onClick={handleLogoClick}>
          {logo}
        </Link>

        <p>
          <span className="hide-for-small">Have an account?</span>{" "}
          <Link data-testid="a:MonarchTrialSignUpHeader" to="/sign-in">
            Sign In
          </Link>
        </p>
      </nav>
    </header>
  );
};

export default SignUpHeader;
